export const appName = 'eScoreIT';
export const version = '2.0';
export const createdBy = 'Powered by Technology Advisory Group LLC and itsTECH LLC';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: true,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'transparent',
  currentuser: null,
  reportCompanyFilter: '',
  reportPeriodType: 'day',
  reportSelectedOrganizationId: 0,
  reportSelectedOrganizationName: '',
  reportExamResultFilter: ''
};

export const API_URL = "https://bcknd.escoreit.com/api"; 
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings, API_URL, createdBy, appName };
