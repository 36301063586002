import React, { Fragment, useState } from 'react';
import { CustomInput, FormGroup, Tooltip } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const RadioAnswer = props => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  function ItemToolTip(props) {
    if (!props.tooltiptext) {
      return null; // No renderizar nada si tooltiptext no tiene valor
    }

    return (
      <Tooltip
        placement={props.placement}
        isOpen={props.isOpen}
        target={props.target}
        toggle={props.toggle}
        style={{
          width: '300px',
          maxWidth: '300px',
          whiteSpace: 'normal'
        }}
      >
        {props.tooltiptext}
      </Tooltip>
    );
  }

  return (
    <Fragment>
      <FormGroup id={'formgroup' + props.id + props.answerid}>
        <CustomInput
          type="radio"
          name={props.name}
          id={props.id + props.answerid}
          label={props.answertext}
          inline={props.inline ? true : false}
          onChange={props.onChange}
          onClick={typeof props.onClick === 'function' ? props.onClick : null}
          value={props.value}
          invalid={props.invalid}
          defaultChecked={props.checked}
          className="custom-radio-answer" // Añadir clase personalizada
        />
      </FormGroup>
      <ItemToolTip
        delay={2000}
        placement="left"
        isOpen={tooltipOpen}
        target={'formgroup' + props.id + props.answerid}
        toggle={toggleTooltip}
        tooltiptext={props.tooltiptext}
      />
    </Fragment>
  );
};

export default RadioAnswer;

RadioAnswer.defaultProps = {
  name: 'answerId',
  id: 'answerId'
};
