import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #ced4da !important', // Borde suave y consistente con !important
    borderRadius: '0.25rem !important', // Redondeo de bordes con !important
    boxShadow: state.isFocused ? '0 0 0 1px #2684FF !important' : 'none !important', // Sombra azul al estar enfocado
    '&:hover': {
      border: '1px solid #2684FF !important' // Borde azul al pasar el mouse con !important
    }
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#f1f1f1 !important', // Color de fondo para los valores seleccionados con !important
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#6c757d !important' // Color del placeholder con !important
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px !important', // Asegurar que no haya margen en el input
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '2px 8px !important', // Ajuste de padding dentro del campo de selección
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0px !important', // Asegurar que no haya padding extra en el contenedor de indicadores
  }),
};

const MultiSelect = forwardRef(({ options, placeholder, ...rest }, ref) => {
  return (
    <Select
      ref={ref}
      closeMenuOnSelect={false}
      isMulti
      options={options}
      placeholder={placeholder}
      classNamePrefix="react-select"
      styles={customStyles}
      {...rest}
    />
  );
});

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string
};

export default MultiSelect;
