import React from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask'; // Importar InputMask

const UserGuidedForm = ({ form, handle_OnChange, errors }) => {
  // Función para limpiar el formato del número telefónico
  const cleanPhoneNumber = phoneNumber => {
    return phoneNumber.replace(/\D/g, ''); // Eliminar cualquier carácter no numérico
  };

  return (
    <form>
      {/* Primera fila: tres columnas con firstname, middlename, lastname */}
      <Row form>
        <Col md={4}>
          <FormGroup>
            <Label for="firstname">First Name</Label>
            <Input
              type="text"
              name="firstname"
              id="firstname"
              value={form.firstname}
              onChange={handle_OnChange}
              maxLength="35"
              invalid={!!errors.firstname}
              required // Campo obligatorio
            />
            {errors.firstname && <div className="invalid-feedback d-block">{errors.firstname}</div>}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="middlename">Middle Name</Label>
            <Input
              type="text"
              name="middlename"
              id="middlename"
              value={form.middlename}
              onChange={handle_OnChange}
              maxLength="15"
            />
            {errors.middlename && <div className="invalid-feedback d-block">{errors.middlename}</div>}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="lastname">Last Name</Label>
            <Input
              type="text"
              name="lastname"
              id="lastname"
              value={form.lastname}
              onChange={handle_OnChange}
              maxLength="45"
              invalid={!!errors.lastname}
              required // Campo obligatorio
            />
            {errors.lastname && <div className="invalid-feedback d-block">{errors.lastname}</div>}
          </FormGroup>
        </Col>
      </Row>

      {/* Segunda fila: dos columnas con email y company */}
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={form.email}
              onChange={handle_OnChange}
              maxLength="35"
              invalid={!!errors.email}
              required // Campo obligatorio
            />
            {errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="company">Company</Label>
            <Input
              type="text"
              name="company"
              id="company"
              value={form.company}
              onChange={handle_OnChange}
              maxLength="35"
              invalid={!!errors.company}
              required // Campo obligatorio
            />
            {errors.company && <div className="invalid-feedback d-block">{errors.company}</div>}
          </FormGroup>
        </Col>
      </Row>

      {/* Tercera fila: dos columnas con phone number y address */}
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="phoneNumber">Phone Number</Label>
            <InputMask
              mask="(999) 999-9999" // Máscara para el formato de número de teléfono de EE.UU.
              maskChar={null}
              value={form.phoneNumber}
              onChange={e => {
                // Limpiar el formato y pasar el número "limpio" al manejador de cambios
                const cleanedValue = cleanPhoneNumber(e.target.value);
                handle_OnChange({
                  target: {
                    name: 'phoneNumber',
                    value: cleanedValue
                  }
                });
              }}
              required // Campo obligatorio
            >
              {inputProps => (
                <Input {...inputProps} type="text" name="phoneNumber" id="phoneNumber" invalid={!!errors.phoneNumber} />
              )}
            </InputMask>
            {errors.phoneNumber && <div className="invalid-feedback d-block">{errors.phoneNumber}</div>}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="address">Address</Label>
            <Input
              type="text"
              name="address"
              id="address"
              value={form.address}
              onChange={handle_OnChange}
              maxLength="50"
              invalid={!!errors.address}
              required // Campo obligatorio
            />
            {errors.address && <div className="invalid-feedback d-block">{errors.address}</div>}
          </FormGroup>
        </Col>
      </Row>
    </form>
  );
};

UserGuidedForm.propTypes = {
  form: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    middlename: PropTypes.string,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired
  }).isRequired,
  handle_OnChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default UserGuidedForm;
