import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect, MemoryRouter, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import AppContext from './../context/Context';

import loadable from '@loadable/component';

const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));

const Layout = () => {
  const { currentuser, setCurrentUser } = useContext(AppContext);

  let mylocation = useLocation();

  const getInitialIndex = pathname => {
    if (pathname === '/authentication/password-reset') return 0;
    if (pathname === '/authentication/registertruck') return 1;
    if (pathname === '/authentication/register') return 2;
    if (pathname === '/authentication/loginexam') return 3;
    return 4;
  };

  useEffect(() => {
    AuthBasicLayout.preload();
  }, []);

  useEffect(() => {
    if (currentuser) {
      if (Date.now() - currentuser.startat > currentuser.jwtExpirationMs) {
        setCurrentUser(null);
      }
    }
    // eslint-disable-next-line
  }, [currentuser]);

  return (
    <>
      <MemoryRouter
        initialEntries={[
          '/authentication/password-reset',
          '/authentication/registertruck',
          '/authentication/register',
          '/authentication/loginexam',
          '/'
        ]}
        initialIndex={getInitialIndex(mylocation.pathname)}
      >
        {/* <Router fallback={<span />}> */}
        <Switch>
          <Route path="/errors" component={ErrorLayout} />
          {/* <Route path={'/authentication/register'} exact component={Registration} /> */}
          {/* <Route path={"/authentication/password-reset"} exact component={PasswordReset} /> */}
          <Route path="/authentication" component={AuthBasicLayout} />
          {/* <Route path={"/reset"} exact component={PasswordReset} /> */}

          {/* <Route component={DashboardLayout} /> */}
          <Route
            path="/"
            render={location =>
              currentuser === null ? <Redirect to="/authentication/login" /> : <DashboardLayout location={location} />
            }
          />
        </Switch>
        <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
        {/* </Router> */}
      </MemoryRouter>
    </>
  );
};

export default Layout;
