import classNames from 'classnames';
import React, { Fragment } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

// Para que el componente CustomTap funcione se debe considerar:
// 1. En su contenedor (padre) se tiene que usar el siguiente codigo: const [activeTab, setActiveTab] = useState('0');
// 2. El valor de la propiedad tabContents se declara como sigue:
//  const tabContents = {
//     tab1: {
//       title: 'Question',
//       content: <RenderDefaultRadioAnswer />
//     },
//     tab2: {
//       title: 'Navigation',
//       onclick: () => InitializeAnsweredQuestions(),
//       content: <h1>Navigation</h1>
//     },
//     tab3: {
//       tapitem: (
//         <NavItem className="cursor-pointer">
//           <NavLink
//             className={classNames({ active: activeTab === '3' })}
//             onClick={() => {
//               window.open('https://www.google.com', '_blank');
//             }}
//           >
//             Set Logo Here
//           </NavLink>
//         </NavItem>
//       ),
//       content: null
//     },
//     ...
//     tabn: {
//       title: 'Tab n',
//       content: <h1>Content para Tab n</h1>
//     }
//   };

const TabItem = ({ tabId, toggle, activeTab, title, onclick, ...rest }) => {
  return (
    <NavItem className="cursor-pointer">
      <NavLink
        className={classNames({ active: activeTab === tabId })}
        onClick={() => {
          if (typeof onclick === 'function') {
            onclick();
          }
          toggle(tabId);
        }}
      >
        {title}
      </NavLink>
    </NavItem>
  );
};

const TapContentItem = ({ tabId, tabContent, ...rest }) => {
  return (
    <TabPane tabId={tabId}>
      <Row>
        <Col sm="12 mt-4">{tabContent}</Col>
      </Row>
    </TabPane>
  );
};

const CustomTab = ({ activeTab, setActiveTab, tabContents, ...rest }) => {
  const toggle = tabId => {
    if (activeTab !== tabId) setActiveTab(tabId);
  };

  return (
    <Fragment>
      <Nav tabs>
        {Object.keys(tabContents).map((key, index) => {
          if (tabContents[key].tapitem) {
            return React.cloneElement(tabContents[key].tapitem, { key: index });
          } else {
            return (
              <TabItem
                key={index}
                tabId={`${index}`}
                toggle={toggle}
                activeTab={activeTab}
                title={tabContents[key].title}
                onclick={tabContents[key].onclick}
              />
            );
          }
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {Object.keys(tabContents).map((key, index) => {
          return <TapContentItem key={index} tabId={`${index}`} tabContent={tabContents[key].content} />;
        })}
      </TabContent>
    </Fragment>
  );
};

export default CustomTab;
