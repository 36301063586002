import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Media, Button
} from 'reactstrap';
import Flex from '../common/Flex';
import Avatar from '../common/Avatar';
import { toast } from 'react-toastify';

import UserService from "../../services/user.service";
import { ucFirst } from "../../helpers/hcutils";
import AppContext from '../../context/Context';

const ProfileDropdown = ({ currentuser, setCurrentUser }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [currentuserdata, setCurrentUserData] = useState(null);
  const [isLoadedUserData, setIsLoadedUserData] = useState(false);
  const {
    setReportCompanyFilter,
    setReportPeriodType,
    setReportSelectedOrganizationId,
    setReportSelectedOrganizationName,
    setReportExamResultFilter
  } = useContext(AppContext);

  const getUser = (id) => {
    UserService.find(id)
      .then(response => {
        setCurrentUserData(response.data);
        setIsLoadedUserData(true);
      })
      .catch(error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        toast.error(`Try again! ${resMessage}`);
      });
  };

  const formatUserName = () => {
    let fullname = '';

    if (isLoadedUserData && currentuserdata) {
      if (currentuserdata.firstname &&
        currentuserdata.firstname.length > 0 &&
        currentuserdata.lastname &&
        currentuserdata.lastname.length > 0) {
        fullname = currentuserdata.firstname + ' ' + currentuserdata.lastname;
      } else if (currentuserdata.firstname && currentuserdata.firstname.length > 0) {
        fullname = currentuserdata.firstname;
      } else {
        fullname = currentuserdata.lastname && currentuserdata.lastname.length > 0 ? currentuserdata.lastname : 'Health Care';
      }
    }
    return fullname;
  };

  useEffect(() => {
    if (currentuser)
      getUser(currentuser.id);
  }, [currentuser])

  return (
    <>
      <Link to="/pages/profile">
        <Button className="remove-border-on-focus" color="transparent" border="none">
          <Media tag={Flex} align="center">
            {isLoadedUserData && <Avatar name={formatUserName()} />}
            {/* {(!isLoadedUserData && currentuser) && <Avatar name={currentuser.username} />} */}
          </Media>
        </Button>
      </Link>

      {
        isLoadedUserData &&
        <Dropdown
          nav
          inNavbar
          isOpen={dropdownOpen}
          toggle={toggle}
          onMouseOver={() => {
            let windowWidth = window.innerWidth;
            windowWidth > 992 && setDropdownOpen(true);
          }}
          onMouseLeave={() => {
            let windowWidth = window.innerWidth;
            windowWidth > 992 && setDropdownOpen(false);
          }}
        >
          <DropdownToggle nav className="pr-0">
            <Media tag={Flex} align="center">
              <Media body>
                <h5 className="mb-0 fs--1">{ucFirst(currentuser.roles[0])}</h5>
                <h5 className="mb-0 fs--1">{currentuser.username}</h5>
              </Media>
            </Media>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-card">
            <div className="bg-white rounded-soft py-2">
              {/* <DropdownItem href="#!">Set status</DropdownItem>
            <DropdownItem tag={Link} to="/pages/profile">
              Profile &amp; account
            </DropdownItem> */}
              {/* <DropdownItem href="#!">Feedback</DropdownItem> */}
              <DropdownItem tag={Link} to="/pages/about">
                About
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={Link} to="/pages/settings">
                Settings
              </DropdownItem>
              <DropdownItem
                tag={Link} to="/authentication/login"
                onClick={() => {
                  if (currentuser) {
                    setCurrentUser(null);
                    setReportCompanyFilter('');
                    setReportPeriodType('day');
                    setReportSelectedOrganizationId(0);
                    setReportSelectedOrganizationName('');
                    setReportExamResultFilter('');
                  }
                }}>
                Logout
              </DropdownItem>
            </div>
          </DropdownMenu>
        </Dropdown>
      }
    </>
  );
};

export default ProfileDropdown;
